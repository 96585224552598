import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		lineHeight: '.7',
		fontWeight: 400,
		marginBottom: '1rem',
		textAlign: 'center',
	},
	header: {
		lineHeight: 1.1,
		fontWeight: 600,
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
	},
	headerText: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontSize: '1.5rem',
		paddingTop: '2rem',
		fontWeight: 500,
		lineHeight: 1.2,
	},
	imgCont: {
		padding: '10rem 0',
		maxWidth: '100vw',
		overflowX: 'hidden',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('lg')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('md')]: {
			padding: '3rem 0 6rem 0',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '3rem 0 2rem 0',
		},

		[theme.breakpoints.down('xs')]: {
			padding: '2rem 0',
			// paddingBottom: '4rem',
			backgroundPosition: 'right',
		},
	},
	logoImg: {
		[theme.breakpoints.down('md')]: {
			maxWidth: '539px',
			marginTop: '3rem',
		},
	},
	pill: {
		backgroundColor: '#0f94a3',
		color: theme.white,
		fontWeight: 500,
		padding: '15px 20px',
		marginTop: '3rem',
		borderRadius: '35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '600px',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
			maxWidth: '530px',
			lineHeight: 1.0,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem',
			maxWidth: '460px',
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: '355px',
			fontSize: '.9rem',
		},
	},
	bullet: {
		listStyle: 'none',
		'&::before': {
			content: `'\\2022'`,
			color: theme.white,
			width: '1em',
			display: 'inline-block',
			marginLeft: '-1em',
		},
	},
	heroTitle: {
		color: '#0f508c',
		fontWeight: 700,
		fontSize: '3rem',
		textAlign: 'center',
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.5rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
	heroSubTitle: {
		color: '#0f508c',
		fontWeight: 500,
		fontSize: '1.75rem',
		textAlign: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.1rem',
		},
	},
}));

export const UCToolkitHero = ({ hero }) => {
	const classes = useStyles();
	const xs = useMediaQuery('(max-width: 481px)');
	return (
		<>
			<div
				className={classes.imgCont}
				style={{
					backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}>
				<Container fixed>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'>
						<GatsbyImage
							image={hero.image?.asset?.gatsbyImageData}
							alt='image'
							className={classes.logoImg}
							imgStyle={{ objectFit: 'contain' }}
						/>
						<Typography
							variant='h4'
							className={classes.pill}
							style={{ color: 'white' }}>
							{hero.pillUCDate}
							{/* css creates bullet with the li tag */}
							<ul
								style={{
									marginBottom: 0,
									marginBlockStart: 0,
									paddingInlineStart: xs ? '20px' : '40px',
								}}>
								<li className={classes.bullet}></li>
							</ul>
							{hero.pillUCLocation}
						</Typography>
						{hero._rawHeroText && (
							<Grid
								container
								item
								direction='row'
								justifyContent='center'
								alignItems='center'
								xs={12}>
								<PortableText
									content={hero._rawHeroText}
									serializers={{
										h1: ({ children }) => (
											<Typography className={classes.heroTitle}>
												{children}
											</Typography>
										),
										normal: ({ children }) => (
											<Typography className={classes.heroSubTitle}>
												{children}
											</Typography>
										),
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Container>
			</div>
		</>
	);
};
