import React, { useState, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { UCToolkitHero } from '../components/UCToolkit/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SEO } from '../components/SEO';

const Video = loadable(() => import('../components/Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69 ',
		fontWeight: 500,
		fontSize: '1.25rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
	},
	textBlueBold: {
		color: theme.workwaveBlue,
		fontWeight: 700,
		fontSize: '1.375rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
	},
	introSection: {
		backgroundColor: '#EFF2F7',
		paddingTop: '4rem',
		paddingBottom: '4rem',
	},
	header: {
		color: theme.workwaveBlue,
		marginBottom: '1rem',
	},
	title: {
		color: theme.workwaveBlue,
		fontWeight: 700,
		lineHeight: '48px',
		marginBottom: '1rem',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '36px',
		},
	},
	descriptionText: {
		color: '#4B5B69 ',
		fontWeight: 500,
		fontSize: '1.25rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		marginBottom: '1rem',
	},
	columns: {
		float: 'left',
		width: '50%',
		marginBottom: '15px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	downloadSection: {
		paddingTop: '2rem',
		paddingBottom: '2rem',
	},
	downloadLink: {
		color: '#183B56',
		fontWeight: 600,
		lineHeight: '32px',
		marginTop: '1rem',
	},
	imgLogoContHeight: {
		minHeight: '200px',
		display: 'flex',
		alignItems: 'center',
		//maxHeight: '200px',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			minHeight: 'fit-content',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	socialMediaIcons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '2rem',
		marginBottom: '2rem',
		[theme.breakpoints.down('xs')]: {
			marginRight: 0,
		},
	},
	button: {
		marginTop: '1rem',
		//marginLeft: '1rem',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem',
		},
	},
	emailLink: {
		fontSize: '1.6875rem',
		color: '#4B5B69 ',
		fontWeight: 500,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
		},
	},
}));

const UCToolkitPage = ({ data, location }) => {
	const toolkit = data.uctoolkit;
	const classes = useStyles();
	const {
		metaTitle,
		metaDescription,
		hero,
		logoHeader,
		logoDescription,
		logoDownloads,
		socialMediaHeader,
		socialMediaDescription,
		socialMediaDownloads,
		partnerBadgeHeader,
		partnerBadgeDescription,
		partnerBadgeDownloads,
		emailSignatureHeader,
		emailSignatureDescription,
		emailSignatureDownloads,
		emailInvitationHeader,
		emailInvitationDescription,
		_rawEmailInvitationCopy,
		emailCTAText,
	} = toolkit;

	const email = useRef(null);
	const [emailCopied, setEmailCopied] = useState(false);
	const [emailError, setEmailError] = useState(false);

	const handleClick = async (e, index) => {
		// Get the email copy
		const copyEmail = email?.current?.children[0].innerText;

		try {
			await navigator.clipboard.writeText(copyEmail);
			setEmailCopied(true);
		} catch (err) {
			setEmailError(true);
		} finally {
			setTimeout(() => {
				setEmailCopied(false);
				setEmailError(false);
			}, 7000);
		}
	};

	const md = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<UCToolkitHero hero={hero} />
			<div className={classes.introSection}>
				<Container>
					<Grid container direction='row' alignItems='center' spacing={3}>
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justifyContent='center'
							item
							xs={12}>
							<Typography variant='h2' className={classes.header}>
								Promote Your Involvement at the WorkWave User Conference
							</Typography>
							<Typography
								variant='body1'
								className={classes.text}
								style={{ marginBottom: '1rem' }}>
								We are so excited to welcome you to the 2025 WorkWave Beyond
								Service User Conference. Please take a moment to review the
								user-friendly and engaging promotional toolkit items below to
								promote your conference involvement to your network.
							</Typography>
							<Typography variant='body1' className={classes.text}>
								This promotional toolkit includes:
							</Typography>
							<ul
								className={classes.text}
								style={{ width: sm ? '100%' : '60%' }}>
								<li className={classes.columns}>Event logo</li>
								<li className={classes.columns}>Social media sharing</li>
								<li className={classes.columns}>Email signature line</li>
								<li className={classes.columns}>
									Customizable email invitation
								</li>
								<li className={classes.columns}>2024 event recap video</li>
								<li className={classes.columns}>Proud partner badge</li>
							</ul>
							<Typography variant='body1' className={classes.textBlueBold}>
								Together, we will make this year’s conference the best yet!
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			<Container>
				<div
					style={{
						paddingTop: sm ? '2rem' : '4rem',
						paddingBottom: sm ? '2rem' : '4rem',
					}}>
					<Grid
						container
						alignItems='center'
						justifyContent='center'
						item
						xs={12}>
						<Typography variant='h2' className={classes.header}>
							Get Sharing
						</Typography>
					</Grid>

					{/* Event Logo */}
					<div className={classes.downloadSection}>
						<Grid item xs={12}>
							<Typography variant='h5' className={classes.title}>
								{logoHeader}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body1' className={classes.descriptionText}>
								{logoDescription}
							</Typography>
						</Grid>
						<Grid container direction='row' alignItems='center' spacing={3}>
							{logoDownloads.map((logo, index) => (
								<Grid
									container
									direction='column'
									alignItems='center'
									justifyContent='center'
									item
									key={index}
									xs={12}
									lg={6}>
									<div className={classes.imgLogoContHeight}>
										<GatsbyImage
											image={logo?.downloadImage?.asset?.gatsbyImageData}
											alt='image'
											imgStyle={{ objectFit: 'contain' }}
										/>
									</div>
									<a
										href={logo.ctaLink}
										style={{ textDecoration: 'none' }}
										target='_blank'>
										<Typography
											variant='body1'
											className={classes.downloadLink}>
											Download
											<span className={classes.arrow}>→</span>
										</Typography>
									</a>
								</Grid>
							))}
						</Grid>
					</div>

					{/* Email Signature Line */}
					<div className={classes.downloadSection}>
						<Grid item xs={12}>
							<Typography variant='h5' className={classes.title}>
								{emailSignatureHeader}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant='body1'
								className={classes.descriptionText}
								style={{ marginBottom: '0rem' }}>
								{emailSignatureDescription}
							</Typography>
						</Grid>
						<Grid container direction='row' alignItems='center' spacing={3}>
							{emailSignatureDownloads.map((email, index) => (
								<Grid
									container
									direction='column'
									alignItems='center'
									justifyContent='center'
									item
									key={index}
									xs={12}
									md={6}>
									<div
										style={{
											minHeight: md ? 'fit-content' : '252px',
											display: 'flex',
											alignItems: 'center',
										}}>
										<GatsbyImage
											image={email?.downloadImage?.asset?.gatsbyImageData}
											alt='image'
											imgStyle={{ objectFit: 'contain' }}
										/>
									</div>
									<a
										href={email.ctaLink}
										style={{ textDecoration: 'none' }}
										target='_blank'>
										<Typography
											variant='body1'
											className={classes.downloadLink}>
											Download
											<span className={classes.arrow}>→</span>
										</Typography>
									</a>
								</Grid>
							))}
						</Grid>
					</div>

					{/* Event Recap Video */}
					<div className={classes.downloadSection}>
						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='center'>
							<Grid item xs={12}>
								<Typography variant='h5' className={classes.title}>
									2024 Event Recap Video
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='body1' className={classes.descriptionText}>
									Encourage your network to participate in WorkWave Beyond
									Service User Conference by sharing this engaging promo video
									on your social networks. Click the&nbsp;
									<FontAwesomeIcon
										style={{ color: '#002D5C' }}
										icon={['fad', 'share-from-square']}
										size='1x'
									/>
									&nbsp; to share on Facebook, Twitter or Linkedin.
								</Typography>
							</Grid>
							<Grid
								container
								direction='column'
								alignItems='center'
								justifyContent='center'
								item
								xs={10}>
								<br />
								<div className={classes.playerWrapper}>
									<Video
										url='https://workwave.wistia.com/medias/7w1ol4knlw'
										className={classes.reactPlayer}
										height='100%'
										width='100%'
										controls={true}
									/>
								</div>
							</Grid>
						</Grid>
					</div>

					{/* Social Media Sharing */}
					<div className={classes.downloadSection}>
						<Grid item xs={12}>
							<Typography variant='h5' className={classes.title}>
								{socialMediaHeader}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body1' className={classes.descriptionText}>
								{socialMediaDescription}
							</Typography>
						</Grid>
						<Grid
							container
							direction={sm ? 'column' : 'row'}
							alignItems={sm ? 'flex-start' : 'center'}
							justifyContent={sm ? 'center' : 'flex-start'}
							item
							xs={12}
							lg={10}>
							<div className={classes.socialMediaIcons}>
								<FontAwesomeIcon
									style={{ color: '#002D5C', marginRight: '1rem' }}
									icon={['fab', 'square-facebook']}
									size='3x'
								/>
								<Typography variant='body1' className={classes.text}>
									Facebook
								</Typography>
							</div>
							<div className={classes.socialMediaIcons}>
								<FontAwesomeIcon
									style={{ color: '#002D5C', marginRight: '1rem' }}
									icon={['fab', 'linkedin']}
									size='3x'
								/>
								<Typography variant='body1' className={classes.text}>
									LinkedIn
								</Typography>
							</div>
							<div className={classes.socialMediaIcons}>
								<FontAwesomeIcon
									style={{ color: '#002D5C', marginRight: '1rem' }}
									icon={['fab', 'square-instagram']}
									size='3x'
								/>
								<Typography variant='body1' className={classes.text}>
									Instagram
								</Typography>
							</div>
							<div className={classes.socialMediaIcons}>
								<FontAwesomeIcon
									style={{ color: '#002D5C', marginRight: '1rem' }}
									icon={['fab', 'square-x-twitter']}
									size='3x'
								/>
								<Typography variant='body1' className={classes.text}>
									Twitter
								</Typography>
							</div>
							<div className={classes.socialMediaIcons}>
								<FontAwesomeIcon
									style={{ color: '#002D5C', marginRight: '1rem' }}
									icon={['fab', 'square-whatsapp']}
									size='3x'
								/>
								<Typography variant='body1' className={classes.text}>
									WhatsApp
								</Typography>
							</div>
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='center'
							spacing={3}>
							<Grid item xs={12}>
								<Typography variant='body1' className={classes.descriptionText}>
									Be sure to select the graphic and hashtags that best fit your
									involvement.
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction='row' alignItems='center' spacing={3}>
							{socialMediaDownloads.map((social, index) => (
								<Grid
									container
									direction='column'
									alignItems='center'
									justifyContent='center'
									item
									key={index}
									xs={12}
									md={4}>
									<div
										style={{
											maxWidth: md ? 'fit-content' : '369px',
											display: 'flex',
											alignItems: 'center',
										}}>
										<GatsbyImage
											image={social?.downloadImage?.asset?.gatsbyImageData}
											alt='image'
											imgStyle={{ objectFit: 'contain' }}
										/>
									</div>
									<a
										href={social.ctaLink}
										style={{ textDecoration: 'none', marginTop: '1rem' }}
										target='_blank'>
										<Typography
											variant='body1'
											className={classes.downloadLink}>
											Download
											<span className={classes.arrow}>→</span>
										</Typography>
									</a>
								</Grid>
							))}
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='flex-start'
							spacing={3}>
							<Grid container direction='column' item xs={10}>
								<Typography variant='body1' className={classes.descriptionText}>
									#BeyondService2025 #UserConference #FieldService #FSM #Partner
									#BeyondService2025 #UserConference #FieldService #FSM
									#Presenter #BeyondService2025 #UserConference #FieldService
									#FSM
								</Typography>
							</Grid>
						</Grid>
					</div>

					{/* Proud Partner Badge */}
					<Grid
						container
						direction='row'
						alignItems='center'
						justifyContent='center'
						className={classes.downloadSection}>
						<Grid item xs={12}>
							<Typography variant='h5' className={classes.title}>
								{partnerBadgeHeader}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body1' className={classes.descriptionText}>
								{partnerBadgeDescription}
							</Typography>
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='center'>
							{partnerBadgeDownloads.map((badge, index) => (
								<Grid
									container
									direction='column'
									alignItems='center'
									justifyContent='center'
									item
									key={index}
									xs={12}
									md={6}>
									<GatsbyImage
										image={badge?.downloadImage?.asset?.gatsbyImageData}
										alt='image'
										imgStyle={{ objectFit: 'contain' }}
									/>
									<a
										href={badge.ctaLink}
										style={{ textDecoration: 'none' }}
										target='_blank'>
										<Typography
											variant='body1'
											className={classes.downloadLink}>
											Download
											<span className={classes.arrow}>→</span>
										</Typography>
									</a>
								</Grid>
							))}
						</Grid>
					</Grid>
				</div>
			</Container>

			<div className={classes.introSection}>
				<Container>
					<Grid container direction='row' alignItems='center' spacing={3}>
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justifyContent='center'
							item
							xs={12}>
							<Typography variant='h5' className={classes.title}>
								{emailInvitationHeader}
							</Typography>
						</Grid>
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justifyContent='center'
							item
							xs={12}>
							<Typography variant='body1' className={classes.descriptionText}>
								{emailInvitationDescription}
							</Typography>
						</Grid>
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justifyContent='center'
							item
							ref={email}
							xs={11}>
							<PortableText
								content={_rawEmailInvitationCopy}
								serializers={{
									normal: ({ children }) => (
										<Typography className={classes.descriptionText}>
											{children}
										</Typography>
									),
									li: ({ children }) => (
										<li className={classes.text}>{children}</li>
									),
									link: (props) => {
										return (
											<a href={props.href} className={classes.descriptionText}>
												{props.children[0]}
											</a>
										);
									},
								}}
							/>
						</Grid>
						<Grid container item>
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								onClick={handleClick}
								style={{
									background: '#002D5C',
									color: 'white',
								}}>
								{emailCTAText}
							</Button>
						</Grid>

						<Grid container item>
							{emailCopied && (
								<Typography style={{ color: 'green', fontWeight: 700 }}>
									Email copied.
								</Typography>
							)}
							{emailError && (
								<Typography style={{ color: 'red', fontWeight: 700 }}>
									Error copying email.
								</Typography>
							)}
						</Grid>
					</Grid>
				</Container>
			</div>

			{/* Additional Info */}
			<Container
				className={classes.downloadSection}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingTop: sm ? '2rem' : md ? '4rem' : '6rem',
				}}>
				<Grid item xs={12} sm={10} style={{ paddingBottom: '4rem' }}>
					<Typography
						variant='h2'
						className={classes.title}
						style={{ textAlign: 'center' }}>
						Have additional promotional toolkit needs, or need information on
						the show exhibitor kit?
					</Typography>
					<Typography
						variant='body1'
						className={classes.emailLink}
						style={{ textAlign: 'center' }}>
						Please email{' '}
						<a
							href='mailto:crystal.martin@workwave.com'
							className={classes.emailLink}>
							crystal.martin@workwave.com
						</a>
						.
					</Typography>
				</Grid>
			</Container>
			{/* <div style={{ marginTop: sm ? '4rem' : md ? '6rem' : '12rem' }}>
      <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div> */}
		</>
	);
};

export const query = graphql`
	query UCToolkitPageQuery {
		uctoolkit: sanityUcToolkit {
			metaTitle
			metaDescription
			hero {
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED, width: 920)
					}
				}
				pillUCDate
				pillUCLocation
				_rawHeroText
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			logoHeader
			logoDescription
			logoDownloads {
				title
				downloadImage {
					asset {
						gatsbyImageData(placeholder: BLURRED, height: 200)
					}
				}
				ctaLink
			}
			socialMediaHeader
			socialMediaDescription
			socialMediaDownloads {
				title
				downloadImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaLink
			}
			emailSignatureHeader
			emailSignatureDescription
			emailSignatureDownloads {
				title
				downloadImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaLink
			}
			partnerBadgeHeader
			partnerBadgeDescription
			partnerBadgeDownloads {
				title
				downloadImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaLink
			}
			emailInvitationHeader
			emailInvitationDescription
			_rawEmailInvitationCopy
			emailCTAText
		}
	}
`;

export default UCToolkitPage;
